(function() {
  var app = {
    init: function() {
      svg4everybody();

      $("#menu-toggler").on("click", function() {
        var $this = $(this);
        var $menu = $("#menu");

        if ($this.hasClass("is-active") || $menu.hasClass("is-active")) {
          $this.removeClass("is-active");
          $menu.removeClass("is-active");
          return;
        }

        $this.addClass("is-active");
        $menu.addClass("is-active");
      });
    }
  };

  app.init();
})();
